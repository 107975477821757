import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue"; // import Datepicker from "@/view/pages/partials/Datepicker.vue";

import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "resource-calendar",
  mixins: [CommonMixin, JobRequestMixin],
  components: {
    DetailTemplate: DetailTemplate,
    FullCalendar: FullCalendar,
    Barcode: Barcode,
    Dialog: Dialog,
    SingleVisitDetail: SingleVisitDetail,
    DateRangePicker: DateRangePicker // Datepicker

  },
  data: function data() {
    return {
      engineerLoading: true,
      openVisitLoading: true,
      eventResizedDialog: false,
      eventDroppedDialog: false,
      eventClickedDialog: false,
      eventLoading: true,
      pageLoading: false,
      calendar: null,
      visitDetail: new Object({
        detail: null,
        visit_id: null,
        customer: null,
        property: null,
        billing: null,
        contactPerson: null
      }),
      visit: {
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_engineer: null,
        previous_visit: null,
        new_visit: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null
      },
      filter: {
        customer: null,
        daterange: [moment().startOf("day").format("YYYY-MM-DD"), moment().endOf("day").format("YYYY-MM-DD")]
      },
      openVisits: [],
      ticketList: [],
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "prev today next",
          center: "title",
          right: "resourceTimelineDay,resourceTimelineThreeDay,resourceTimelineWeek,resourceTimelineFifteenDay,resourceTimelineThirtyDay"
        },
        aspectRatio: 2.2,
        initialView: "resourceTimelineDay",
        views: {
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            duration: {
              days: 3
            },
            buttonText: "3 days"
          },
          resourceTimelineFifteenDay: {
            type: "resourceTimeline",
            duration: {
              days: 15
            },
            buttonText: "15 days"
          },
          resourceTimelineThirtyDay: {
            type: "resourceTimeline",
            duration: {
              days: 30
            },
            buttonText: "30 days"
          }
        },
        initialDate: moment().startOf("day").format("YYYY-MM-DD"),
        timeZone: process.env.VUE_APP_TIMEZONE,
        scrollTime: "08:00",
        editable: false,
        selectable: false,
        droppable: false,
        nowIndicator: true,
        eventResize: this.eventResized,
        eventDrop: this.eventDropped,
        eventClick: this.eventClicked,
        drop: this.dropped,
        events: this.getCalendarEvents,
        datesSet: this.dateChanged,
        resourceAreaWidth: "20%",
        resourceAreaColumns: [{
          headerContent: "Engineer Name",
          field: "full_name"
        }],
        resources: this.getResources
      },
      customerList: [],
      resizedEvent: {},
      droppedEvent: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    _this.getOpenVisits();

    _this.getCalendarCustomer();

    _this.getCalendarTicket();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Resource Calendar"
    }]);

    _this.calendar = this.$refs["resourceCalendar"].getApi();

    if (_this.getPermission("calendar:update")) {
      _this.calendar.setOption("editable", true);

      _this.calendar.setOption("selectable", true);

      _this.calendar.setOption("droppable", true);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.calendar.destroy();
  },
  methods: {
    filterDateRange: function filterDateRange() {
      if (this.filter.daterange && this.lodash.isArray(this.filter.daterange) && this.filter.daterange.length === 2) {
        var startDate = moment(this.filter.daterange[0]);
        var endDate = moment(this.filter.daterange[1]);

        if (moment(endDate).isAfter(startDate)) {
          this.getOpenVisits();
        }
      }
    },
    visitScheduleUpdated: function visitScheduleUpdated() {
      setTimeout(function () {
        window.location.reload();
      }, 500);
    },
    loadContent: function loadContent(visitId, jobId) {
      var _this = this;

      _this.pageLoading = true;

      _this.getJob(jobId).then(function (_ref) {
        var ticket = _ref.ticket,
            customer = _ref.customer,
            property = _ref.property,
            billing = _ref.billing,
            contact_person = _ref.contact_person;
        _this.visitDetail = new Object({
          detail: ticket || new Object(),
          visit_id: visitId,
          customer: customer || new Object(),
          property: property || new Object(),
          billing: billing || new Object(),
          contactPerson: contact_person || new Object()
        });
      }).catch(function (error) {
        _this.logError(error);

        _this.$router.go(-1);
      }).finally(function () {
        _this.eventClickedDialog = true;
        _this.pageLoading = false;
      });
    },
    updateFilterDate: function updateFilterDate(date) {
      if (this.calendar && date) {
        this.calendar.gotoDate(date);
      }

      this.getOpenVisits();
    },
    initExternalDraggable: function initExternalDraggable() {
      var _this = this;

      if (_this.getPermission("calendar:update")) {
        var containerEl = document.getElementById("external-draggable");
        new Draggable(containerEl, {
          itemSelector: ".fc-event",
          eventData: function eventData(eventEl) {
            try {
              if (eventEl.dataset) {
                var event = JSON.parse(eventEl.dataset.event);
                return {
                  title: event.barcode + " | " + event.full_name
                };
              }
            } catch (error) {
              return {
                title: eventEl.innerText
              };
            }
          }
        });
      }
    },
    eventResized: function eventResized(_eventResized) {
      try {
        this.resizedEvent = _eventResized;
        var event = _eventResized.event;
        var oldEvent = _eventResized.oldEvent;
        this.visit = new Object({
          engineer_barcode: null,
          engineerId: null,
          previous_visit_barcode: null,
          previous_visit_title: null,
          previous_status_text: null,
          new_visit_barcode: null,
          new_visit_title: null,
          new_status_text: null,
          previous_engineer: null,
          previous_visit: null,
          new_visit: null,
          new_engineer: null,
          oldId: null,
          id: event.extendedProps.visitId,
          barcode: event.extendedProps.visitBarcode,
          previous_started_at: oldEvent.startStr.substr(0, 19),
          previous_finished_at: oldEvent.endStr.substr(0, 19),
          new_started_at: event.startStr.substr(0, 19),
          new_finished_at: event.endStr.substr(0, 19)
        });
        this.eventResizedDialog = true;
      } catch (error) {
        this.logError(error);

        _eventResized.revert();
      }
    },
    rescheduleVisit: function rescheduleVisit() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "visit/" + _this.visit.id + "/re-schedule",
        data: {
          date: moment(_this.visit.new_started_at).format("YYYY-MM-DD"),
          start_time: moment(_this.visit.new_started_at).format("HH:mm"),
          end_time: moment(_this.visit.new_finished_at).format("HH:mm")
        }
      }).then(function () {
        _this.eventResizedDialog = false;
        _this.resizedEvent = new Object({});
      }).catch(function (error) {
        _this.logError(error);

        _this.resizedEvent.revert();

        _this.resizedEvent = new Object({});
      }).finally(function () {
        _this.pageLoading = false;

        _this.reloadCalendar();

        _this.getOpenVisits();
      });
    },
    reAllocateEngineer: function reAllocateEngineer() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "calendar/re-assign-visit",
        data: _this.visit
      }).then(function () {
        _this.eventDroppedDialog = false;
        _this.droppedEvent = new Object({});
      }).catch(function (error) {
        _this.logError(error);

        _this.droppedEvent.revert();

        _this.droppedEvent = new Object({});
      }).finally(function () {
        _this.pageLoading = false;

        _this.reloadCalendar();
      });
    },
    closeEventResized: function closeEventResized() {
      this.eventResizedDialog = false;

      if (this.lodash.isEmpty(this.resizedEvent) === false) {
        this.resizedEvent.revert();
        this.resizedEvent = new Object({});
      }

      this.visit = new Object({
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_visit: null,
        new_visit: null,
        previous_engineer: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null
      });
    },
    eventDropped: function eventDropped(_eventDropped) {
      try {
        this.droppedEvent = _eventDropped;
        var event = _eventDropped.event,
            oldEvent = _eventDropped.oldEvent,
            newResource = _eventDropped.newResource,
            oldResource = _eventDropped.oldResource;
        this.visit = new Object({
          id: newResource.id,
          oldId: oldResource.id,
          engineerId: newResource.id,
          barcode: null,
          engineer_barcode: newResource.extendedProps.barcode,
          previous_visit: oldEvent.extendedProps,
          previous_engineer: oldResource.extendedProps,
          previous_visit_barcode: oldEvent.extendedProps.visitBarcode,
          previous_visit_title: oldEvent.extendedProps.visitTitle,
          previous_status_text: oldEvent.extendedProps.statusText,
          previous_started_at: oldEvent.extendedProps.startedAt,
          previous_finished_at: oldEvent.extendedProps.finishedAt,
          new_engineer: newResource.extendedProps,
          new_visit: event.extendedProps,
          new_visit_barcode: event.extendedProps.visitBarcode,
          new_visit_title: event.extendedProps.visitTitle,
          new_status_text: event.extendedProps.statusText,
          new_started_at: event.extendedProps.startedAt,
          new_finished_at: event.extendedProps.finishedAt
        });
        this.eventDroppedDialog = true;
      } catch (error) {
        this.logError(error);

        _eventDropped.revert();
      }
    },
    closeEventDropped: function closeEventDropped() {
      this.eventDroppedDialog = false;

      if (this.lodash.isEmpty(this.droppedEvent) === false) {
        this.droppedEvent.revert();
        this.droppedEvent = new Object({});
      }

      this.visit = new Object({
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_engineer: null,
        previous_visit: null,
        new_visit: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null
      });
    },
    eventClicked: function eventClicked(_ref2) {
      var event = _ref2.event;
      var extendedProps = event.extendedProps;
      this.loadContent(extendedProps.visitId, extendedProps.ticketId);
    },
    dateChanged: function dateChanged(_ref3) {
      var startStr = _ref3.startStr,
          endStr = _ref3.endStr;
      this.filter.daterange = [moment(startStr).format("YYYY-MM-DD"), moment(endStr).format("YYYY-MM-DD")];
      this.reloadCalendar();
      this.getOpenVisits();
    },
    dropped: function dropped(_ref4) {
      var resource = _ref4.resource,
          draggedEl = _ref4.draggedEl;

      var _this = this;

      var dataset = draggedEl.dataset;
      var visit = JSON.parse(dataset.event);
      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "assign-engineer-by-date",
        data: {
          started_at: visit.started_at,
          finished_at: visit.finished_at,
          visit: visit.id,
          engineer: resource.id
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;

        _this.reloadCalendar();
      });
    },
    reloadCalendar: function reloadCalendar() {
      try {
        if (this.calendar) {
          this.calendar.removeAllEvents();
          this.calendar.refetchResources();
          this.calendar.refetchEvents();
        }
      } catch (error) {
        this.logError(error);
        window.location.reload(true);
      }
    },
    getCalendarCustomer: function getCalendarCustomer() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "calendar/filter-customer"
      }).then(function (_ref5) {
        var data = _ref5.data;
        _this.customerList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getCalendarTicket: function getCalendarTicket() {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "calendar/filter-ticket",
        data: _this.filter
      }).then(function (_ref6) {
        var data = _ref6.data;
        _this.ticketList = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getCalendarEvents: function getCalendarEvents(fetchInfo, successCallback, failureCallback) {
      var _this = this;

      _this.eventLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "calendar/resource-events",
        data: _this.filter
      }).then(function (_ref7) {
        var data = _ref7.data;
        successCallback(data);
      }).catch(function (error) {
        failureCallback(error);
      }).finally(function () {
        _this.eventLoading = false;
      });
    },
    getOpenVisits: function getOpenVisits() {
      var _this = this;

      _this.openVisitLoading = true;

      if (_this.lodash.isEmpty(_this.filter) === false) {
        _this.$store.dispatch(QUERY, {
          url: "calendar/open-visits",
          data: _this.filter
        }).then(function (_ref8) {
          var data = _ref8.data;
          _this.openVisits = data;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.openVisitLoading = false;

          if (_this.openVisits && _this.openVisits.length > 0) {
            setTimeout(_this.initExternalDraggable, 200);
          }
        });
      }
    },
    getResources: function getResources(fetchInfo, successCallback, failureCallback) {
      var _this = this;

      _this.engineerLoading = true;

      _this.$store.dispatch(GET, {
        url: "calendar/working-engineers"
      }).then(function (_ref9) {
        var data = _ref9.data;
        successCallback(data);
      }).catch(function (error) {
        failureCallback(error);
      }).finally(function () {
        _this.engineerLoading = false;
      });
    }
  },
  computed: {
    initialDateRange: function initialDateRange() {
      var dateRange = this.lodash.cloneDeep(this.filter.daterange);

      if (dateRange && this.lodash.isArray(dateRange) && dateRange.length === 2) {
        var startDate = moment(dateRange[0]);
        var endDate = moment(dateRange[1]);

        if (moment(endDate).isAfter(startDate)) {
          return dateRange;
        }
      }

      return [];
    },
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 60);
    }
  }
};